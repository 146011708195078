import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { db } from '../../../Components/Firebase';
import '../../../Functions/Styles/Manager/Data/AddStudentsFile.css';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from '../../../Components/Loading';

const AddStudentsFile = () => {
  const [excelData, setExcelData] = useState([]);
  const [headerRowIndex, setHeaderRowIndex] = useState(0);
  const [columnSelections, setColumnSelections] = useState({});
  const { schoolId, classid } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const savedSelections = localStorage.getItem('columnSelections');
    const savedHeaderRow = localStorage.getItem('headerRowIndex');

    if (savedSelections) {
      setColumnSelections(JSON.parse(savedSelections));
    }
    if (savedHeaderRow) {
      setHeaderRowIndex(Number(savedHeaderRow));
    }
  }, []);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
      setExcelData(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.xlsx, .xls',
  });

  const handleHeaderRowChange = (event) => {
    const newIndex = Number(event.target.value);
    setHeaderRowIndex(newIndex);
    localStorage.setItem('headerRowIndex', newIndex);
    setColumnSelections({});
    localStorage.removeItem('columnSelections');
  };

  const handleColumnSelection = (column, value) => {
    const updatedSelections = {
      ...columnSelections,
      [column]: value,
    };
    setColumnSelections(updatedSelections);
    localStorage.setItem('columnSelections', JSON.stringify(updatedSelections));
  };

  const columnOptions = [
    { value: 'firstName', label: 'שם פרטי' },
    { value: 'lastName', label: 'שם משפחה' },
    { value: 'birthday', label: 'תאריך לידה' },
    { value: 'dadName', label: 'שם אב' },
    { value: 'momName', label: 'שם אם' },
    { value: 'gender', label: 'מין' },
    { value: 'phoneNumber1', label: 'טלפון 1' },
    { value: 'phoneNumber2', label: 'טלפון 2' },
  ];

  const getAvailableOptions = (currentColumn) => {
    const selectedValues = Object.values(columnSelections).filter(
      (value) => value && columnSelections[currentColumn] !== value
    );
    return columnOptions.filter(
      (option) => !selectedValues.includes(option.value)
    );
  };

  const displayStudentData = () => {
    const selectedColumns = Object.keys(columnSelections).reduce((acc, index) => {
      const columnName = columnSelections[index];
      if (columnName) acc[index] = columnName;
      return acc;
    }, {});

    return excelData.slice(headerRowIndex + 1).map((row) => {
      const studentData = {};
      Object.entries(selectedColumns).forEach(([colIndex, colName]) => {
        studentData[colName] = row[colIndex];
      });

      const phoneNumbers = [];
      if (studentData.phoneNumber1) phoneNumbers.push(studentData.phoneNumber1);
      if (studentData.phoneNumber2) phoneNumbers.push(studentData.phoneNumber2);
      studentData.phoneNumbers = phoneNumbers;

      delete studentData.phoneNumber1;
      delete studentData.phoneNumber2;

      return studentData;
    });
  };

  const cleanPhoneNumber = (phone) => {
    return phone.replace(/\D/g, '');
  };

  const addStudentsToFirestore = async () => {
    setLoading(true);
    const students = displayStudentData();
    const batch = db.batch();

    students.forEach((student) => {
      const studentWithClass = {
        ...student,
        class: classid,
        preM: true,
        status: "Available",
        phoneNumbers: student.phoneNumbers.map(cleanPhoneNumber),
      };

      const studentRef = db.collection("Schools").doc(schoolId).collection("Students").doc();
      batch.set(studentRef, studentWithClass);
    });

    try {
      await batch.commit();
      navigate(-1);
    } catch (error) {
      console.error('Error adding students: ', error);
      alert('Failed to add students. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (excelData.length > 0 && Object.keys(columnSelections).length > 0) {
      displayStudentData();
    }
  }, [excelData, columnSelections, headerRowIndex]);

  return (
    <div className="excel-drop-container">
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <p>גרור ושחרר קובץ Excel כאן, או לחץ כדי לבחור אחד</p>
      </div>

      {loading && <Loading />}

      {excelData.length > 0 && !loading && (
        <>
          <div>
            <label htmlFor="headerRow">בחר שורת כותרת:</label>
            <select
              id="headerRow"
              onChange={handleHeaderRowChange}
              value={headerRowIndex}
            >
              {excelData.map((_, index) => (
                <option key={index} value={index}>
                  {`Row ${index}`}
                </option>
              ))}
            </select>
            <button onClick={addStudentsToFirestore}>הוסף תלמידים</button>
          </div>

          <div className="excel-data">
            <table>
              <thead>
                <tr>
                  {excelData[headerRowIndex] &&
                    excelData[headerRowIndex].map((column, index) => (
                      <th key={index}>
                        <select
                          onChange={(e) =>
                            handleColumnSelection(index, e.target.value)
                          }
                          value={columnSelections[index] || ''}
                        >
                          <option value="">בחר</option>
                          {getAvailableOptions(index).map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </th>
                    ))}
                </tr>
                <tr>
                  {excelData[headerRowIndex] &&
                    excelData[headerRowIndex].map((header, index) => (
                      <th key={index}>{header || `Column ${index + 1}`}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {excelData.slice(headerRowIndex + 1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default AddStudentsFile;
